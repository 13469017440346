
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'
import { PropOptions } from 'vue'
import { Card, UiImage, UiTitle } from '~/components/atoms'
import { IPage } from '~/models/base/Page'

export default {
  components: {
    Card,
    UiImage,
    UiTitle,
  },
  props: {
    brand: VueTypes.shape({
      slug: VueTypes.string,
      logo: VueTypes.object,
      title: VueTypes.string,
    }).loose.def({}),
    enableLinks: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    categoryPage: {
      type: Object,
      default: () => ({}),
    } as PropOptions<IPage | Record<string, unknown>>,
  },
  computed: {
    ...mapGetters('context', ['marketplacePrefix', 'country']),
  },
  methods: {
    handleClick(): void {
      this.$gtmEnhanced.trackBrandClick(
        this.brand,
        this.gtmTrackSectionName || this.categoryPage?.slug
      )
      this.$navigate(this.brand.slug)
    },
  },
}
